import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import LocaleContext from "../../LocaleContext";
import {NavLink} from "react-router-dom";

const Home = () => {
    const {t} = useTranslation();
    const {locale} = useContext(LocaleContext);

    return (
        <div>
            <header>
                <img src={`images/background/Exhibition-${locale.split("-")[0]}.png`} width={"100%"}>
                </img>
            </header>
            <section className="section-4">
                <div className="container">
                    <div className="row text-center mrg-top-10 justify-content-around">
                        <div className="col-md-3">
                            <NavLink to="/hall_map">
                            <div className="exhibition-info">
                                <i className="ti-map font-size-40 mrg-btm-30"/>
                                <p className="sub-tittle">{t("hall_map")}</p>
                            </div>
                            </NavLink>
                        </div>

                        <div className="col-md-3">
                            <NavLink to="/expo_map">
                            <div className="exhibition-info">
                                <i className="ti-map-alt font-size-40 mrg-btm-30"/>
                                <p className="sub-tittle">{t("expo_map")}</p>
                            </div>
                            </NavLink>
                        </div>

                        {/*<div className="col-md-3">*/}
                        {/*    <NavLink to="/about">*/}
                        {/*    <div className="exhibition-info">*/}
                        {/*        <i className="ti-info font-size-40 mrg-btm-30"/>*/}
                        {/*        <p className="sub-tittle">{t("about_expo")}</p>*/}
                        {/*    </div>*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}

                        <div className="col-md-3">
                            <NavLink to="/work_hours">
                            <div className="exhibition-info">
                                <i className="ti-alarm-clock font-size-40 mrg-btm-30"/>
                                <p className="sub-tittle">{t("work_hours")}</p>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
