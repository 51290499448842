import {useContext, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import LocaleContext from "../LocaleContext";
import i18n from "../i18n";
import {NavLink} from "react-router-dom";

function Navigation() {
    const {t} = useTranslation();
    const {locale} = useContext(LocaleContext);
    const [isOpened , setIsOpened] = useState(false)

    function changeLocale(l) {
        if (locale !== l) {
            i18n.changeLanguage(l);
        }
    }

    return (
        <Navbar className="main-nav menu-blue" expand="lg" variant="dark" sticky="top" fixed="top"
                onToggle={setIsOpened}
                expanded={isOpened}>
            <Container>
                <div className="navbar-header">
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav /*className={locale === 'en' ? 'ms-auto' : 'me-auto'}*/ onClick={()=>setIsOpened(false)}>

                        <NavLink to="/">{t("home")}</NavLink>
                        <NavLink to="/expo_map">{t("expo_map")}</NavLink>
                        <NavLink to="/hall_map">{t("hall_map")}</NavLink>
                        {/*<NavLink to="/about">{t("about_expo")}</NavLink>*/}
                        <NavLink to="/work_hours">{t("work_hours")}</NavLink>
                        {/*<NavLink to="time-exhen.html">{t("previous_expos")}</NavLink>*/}
                        {/*<NavLink to="#" onClick={() => changeLocale(locale === 'en-US'?'ar-KW':'en-US')}>{locale === 'en-US' ?'العربية':'English'}</NavLink>*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
