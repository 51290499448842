import React from 'react';
import {useTranslation} from "react-i18next";

const WorkHours = () => {
    const {t} = useTranslation();

    return (
        <div>

            <section className="section " style={{"backgroundImage": "url(images/background/bg.png)"}}>
                <div className="container mrg-top-20">
                    <h1 className="text-center heading  " style={{"fontSize": "xx-large"}}>{t("work_hours")}</h1>
                </div>
                <div className="container mrg-top-45">


                    <h3 className="text-center" data-brackets-id="4444"
                        style={{"color": "#259fe3", "fontSize": "x-large"}}>{t("time1_title")}</h3>
                    <p className="sub-heading-center">
                        {t("time1_details")}<br/>
                    </p>
                    <br/>
                    <h3 className="text-center" data-brackets-id="4444"
                        style={{"color": "#259fe3", "fontSize": "x-large"}}>{t("time2_title")}</h3>
                    <p className="sub-heading-center">
                        {t("time2_details")}
                    </p>

                    <br/>
                    <h3 className="text-center" data-brackets-id="4444"
                        style={{"color": "#259fe3", "fontSize": "x-large"}}>{t("time3_title")}</h3>
                    <p className="sub-heading-center">
                        {t("time3_details")}
                    </p>

                </div>
            </section>
        </div>
    );
}

export default WorkHours;
