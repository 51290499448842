import React from 'react';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();

    return (
        <footer  className="bg-dark" >
            <div className="footer-bottom">
                <div className="container">
                    <small className="copyright pull-left">{t("copyright")}</small>
                    <div className="social-icon pull-right">
                        <a href="https://www.facebook.com/boutiqaat"><i className="fa fa-facebook"/></a>
                        <a href="https://twitter.com/boutiqaat"><i className="fa fa-twitter"/></a>
                        <a href="https://www.instagram.com/boutiqaat/"><i className="fa fa-instagram"/></a>
                        <a href="https://www.youtube.com/channel/UCzpFllnXGMn2sDMKncrNjPg"><i className="fa fa-youtube-play"/></a>
                        <a href="https://www.snapchat.com/add/boutiqaat"><i className="fa fa-snapchat"/></a>
                        {/*<a href="#"><i className="fa fa-map-pin"/></a>*/}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
