import React from 'react';
import {useTranslation} from "react-i18next";

const ExpoMap = () => {
    const {t} = useTranslation();

    return (
        <div>
            <header>
                <div className="fw-slider">
                    <div id="fw-slider-nav" className="carousel slide carousel-fade" data-ride="carousel">
                        <div className="carousel-inner">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13921.570930440754!2d48.046865!3d29.270796000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x82a12e9f4958c2e8!2sKuwait%20International%20Fairground!5e0!3m2!1sen!2sus!4v1636964338375!5m2!1sen!2sus"
                                width="1920" height="2160" style={{"border": 0}} allowFullScreen="" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </header>
            <section id="contact" className="section-2">
                <div className="container">
                    <div className="row">
                        <h2 className="heading">{t("address")}</h2>
                        <p className="sub-heading-center ">{t("address_details")}</p>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default ExpoMap;
