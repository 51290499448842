import React, {Suspense, useState} from 'react';
import {ThemeProvider} from 'react-bootstrap';
import {HashRouter, Route, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './components/Loading';
import Navigation from './components/Navigation';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import Home from "./components/home/home";
import About from "./components/about/about";
import Error from "./components/error/error";
import ExpoMap from "./components/expo_map/expo_map";
import WorkHours from "./components/work_hours/work_hours";
import HallMap from "./components/hall_map/hall_map";
import Footer from "./components/footer/footer";

function App() {
    const [locale, setLocale] = useState(i18n.language|| "ar-KW");

    i18n.on('languageChanged', (lng) => setLocale(i18n.language));

    return (
        <LocaleContext.Provider value={{locale, setLocale}}>
            <Suspense fallback={<Loading/>}>
                <Helmet htmlAttributes={{
                    lang: locale,
                    dir: locale === 'en-US' ? 'ltr' : 'rtl'
                }}/>
                <ThemeProvider dir={locale === 'en-US' ? 'ltr' : 'rtl'}>
                    <div className="top-logo" dir={'ltr'}>
                        <a className="" href="#">
                            <img src={"images/logo/Botiqaatfair.png"} alt="Exort"/>
                        </a>
                        <a className="" href="#">
                            <img src={"images/logo/Fair.png"} alt="Exort"/>
                        </a>
                    </div>
                    <HashRouter basename={process.env.PUBLIC_URL}>
                        <Navigation/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/expo_map" element={<ExpoMap/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/work_hours" element={<WorkHours/>}/>
                            <Route path="/hall_map" element={<HallMap/>}/>
                            <Route element={<Error/>}/>
                        </Routes>
                    </HashRouter>
                    <Footer/>

                </ThemeProvider>
            </Suspense>
        </LocaleContext.Provider>
    );
}

export default App;
