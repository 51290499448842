import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Autosuggest from 'react-autosuggest';

const HallMap = () => {
    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState('')
    const [searchMessage, setSearchMessage] = useState('')
    const [animate, setAnimate] = useState(false)
    const [searchResult, setSearchResult] = useState([])
    const [brandNames, setBrandNames] = useState([])
    const [suggestions, setSuggestions] = useState([])
    useEffect(() => {
        fetch('https://exhibitions-api.boutiqaat.com/Exhibition/BrandList', {
            "method": "GET",
            "headers": {
                "Token": 'BtqEx@2021',
                "Content-Type": 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                   setBrandNames(result.brand)
                },
                (_) => {

                }
            )
    }, [])
    const searchForProduct = () => {
        setAnimate(false)
        setSearchMessage('')
        fetch('https://exhibitions-api.boutiqaat.com/Exhibition/info', {
            "method": "POST",
            "headers": {
                "Token": 'BtqEx@2021',
                "Content-Type": 'application/json'
            },
            "body": `{"brand": "${searchTerm}" }`
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setSearchResult(result.booth);
                    setSearchMessage(result.message)
                    setAnimate(true)
                },
                (_) => {
                    setSearchResult([]);
                    setSearchMessage('something went wrong')
                    setAnimate(false)
                }
            )
    }

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : brandNames.filter(brand =>
            brand.toLowerCase().slice(0, inputLength) === inputValue
        ).slice(0 , 5);
    };


    const getSuggestionValue = suggestion => {
        searchForProduct(suggestion)
        return suggestion
    };

    const renderSuggestion = suggestion => (
        <div>
            {suggestion}
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
        // setSearchTerm("")
    };

    console.log(suggestions) ;

    return (
        <section className="section " style={{"backgroundImage": "url(images/background/bg.png) ; flex:1"}}>
            <div className="container">
                <h1 className="text-center heading" style={{"fontSize": "xx-large"}}>{t("hall_map")}</h1>

                <div className="cta mrg-top-40 no-padding">
                    <div className="news-search border">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={{
                                placeholder: t("search_about_holder"),
                                value:searchTerm,
                                onChange: (event, { newValue }) => {
                                    setSearchTerm(newValue);
                                }
                            }}

                        />
                        {/*<button className="btn-style-2 no-border" onClick={() => searchForProduct()}>*/}
                        {/*    <i className="ti-search font-size-12"/>*/}

                        {/*    <span className="mrg-left-5 mrg-right-5">{t("search")}</span>*/}

                        {/*</button>*/}
                    </div>
                </div>
            </div>
            <div className="container mrg-top-40 center">
                <div className="row">
                    <h2 className="heading">{searchTerm} </h2>
                    <p className={`sub-heading-center box ${animate && "bounce-7"}`}
                       style={{fontSize: 16, fontWeight: 'bold', color:searchResult.length?"forestgreen" :"indianred"}}>
                        {searchResult.length ? `Booth ${searchResult.join(', ')}` : (searchMessage)}
                    </p>
                </div>

            </div>
            <div className="content mrg-horizon-100 mrg-top-40 center">
                <img className="img-responsive" src="images/background/map.png" alt=""/>
            </div>

        </section>
    );
}

export default HallMap;
