import React from 'react';
import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation();

    return (
        <div>
            <section className="section " style={{"background-image": "url(images/background/bg.png"}}>
                <div className="container">
                    <h1 className="text-center heading  " style={{"font-size": "xx-large"}}>{t("about_expo")}</h1>
                    <br/>
                    <p className="sub-heading">
                        {t("about_details1")}

                        <br/>
                        {t("about_details2")}

                        <br/>
                        {t("about_details3")}
                    </p>
                    <p className="sub-heading">
                        {t("about_details4")}
                    </p>

                </div>
            </section>
        </div>
    );
}

export default About;


